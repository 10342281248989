<template>
  <div class="list-wrapper">
    <ul
      :class="{
        'list-possible-hover': isPossibleHoverList,
        'list-factory': isFactoryComp,
        'list-is-edit': isEdit,
      }"
    >
      <li class="list-header">
        <span v-for="(header, index) in roleByHeaderList" :key="index">
          <span>{{ header.title }}</span>
          <button v-if="header['sortingKey']" @click="onClickSortButton(header)">
            <img src="/images/arrow_sorting.svg" alt="arrow_sorting" />
          </button>
          <template v-if="header['AdditionalInfo']">
            <button class="tool-tip-button" @click="toggleToolTip">
              <vue-material-icon name="info" :size="12" />
              <span class="tool-tip" :class="{ 'tool-tip-show': isShowToolTip }">
                {{ header['AdditionalInfo'] }}
              </span>
            </button>
          </template>
        </span>
      </li>
      <draggable
        :sort="!!isDraggable"
        v-model="showedList"
        handle=".drag-handle"
        :class="{ 'list-has-scroll': hasScrollList }"
        v-if="list && list.length !== 0"
      >
        <slot name="list-body" />
      </draggable>
    </ul>
    <div :class="{ 'list-data-none': list === null || (list && list.length === 0) }">
      <loading-bar v-if="list === null" />
      <div v-if="list && list.length === 0">
        <slot name="no-list-description" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import draggable from 'vuedraggable';
import LoadingBar from '../loadingBar/loadingBar';
import isEmptyObject from '@/asset/js/isEmptyObject';

export default {
  name: 'List',
  props: ['list', 'headerList', 'isPossibleHoverList', 'isNoList', 'isDraggable', 'hasScrollList', 'isEdit'],
  data() {
    return {
      roleByHeaderList: [],
      isShowToolTip: false,
      showedList: [],
    };
  },
  computed: {
    ...mapState('users', ['currentCompany']),
    ...mapGetters('users', ['isFactoryComp', 'isManufacturerComp']),
  },
  created() {},
  mounted() {
    this.setListHeader();
  },
  watch: {
    list() {
      if (this.list) {
        this.showedList = this.list;
      }
    },
    showedList(newVal, oldVal) {
      if (!isEmptyObject(oldVal)) {
        this.$emit('changedList', this.showedList);
      }
    },
  },
  methods: {
    toggleToolTip() {
      this.isShowToolTip = !this.isShowToolTip;
    },
    setListHeader() {
      this.roleByHeaderList = this.headerList.filter((header) => {
        return this.isManufacturerComp ? header['isShowManufacturerComp'] : header['isShowFactoryComp'];
      });
    },
    onClickSortButton(header) {
      if (header['sortingKey']) {
        this.$emit('sort', header['sortingKey']);
      }
    },
  },
  components: { draggable, LoadingBar },
};
</script>

<style scoped lang="scss">
@import 'List';
</style>
